<template>
  <div class="col-12 user-form">
    <div v-if="!universities || isLoading" class="loader">Loading...</div>
    <form v-else @submit.prevent="submitForm">
      <h1 class="text-uppercase common-text">
        {{
          pageContent && pageContent.formHeader
            ? pageContent.formHeader
            : "YOUR STUDY BACKGROUND"
        }}
      </h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="welcome-desc font-weight-normal">
        {{
          pageContent && pageContent.formSubHeader
            ? pageContent.formSubHeader
            : "Tell us a bit more about your study background so we can guide you in the right direction relevant to your study background."
        }}
      </h4>
      <div class="user-form-wizard" :class="formData.area ? '' : 'guide'">
        <div class="form-group text-left">
          <label>
            {{
              pageContent && pageContent.formInstitutionLabel
                ? pageContent.formInstitutionLabel
                : "Your educational institution? *"
            }}</label
          >
          <select
            class="form-control text-secondary"
            v-model="formData.institution"
            @change="getCurrentUniversityData(), totalCleanup()"
            required
          >
            <option
              value="null"
              disabled
              v-if="
                universities.universities &&
                universities.universities.length !== 1
              "
            >
              {{
                pageContent && pageContent.formInstitutionPlaceholder
                  ? pageContent.formInstitutionPlaceholder
                  : "Select from list"
              }}
            </option>
            <option
              v-for="(institute, index) in universities.universities"
              :key="index"
              :value="{
                uid: institute.id,
                id: institute.university_id,
                name: institute.university_name,
              }"
            >
              {{ institute.university_name }}
            </option>
          </select>
        </div>
        <div
          class="pj-loading"
          v-if="
            !universityLoaded &&
            formData.institution &&
            formData.institution.name !== 'Other'
          "
        >
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
        <div
          class="form-group text-left"
          v-if="formData.institution && formData.institution.name === 'Other'"
        >
          <label for>{{
            pageContent && pageContent.otherInstitutionNameLabel
              ? pageContent.otherInstitutionNameLabel
              : "What is the name of your educational institution? *"
          }}</label>
          <input
            type="text"
            :placeholder="pageContent && pageContent.otherInstitutionNamePlaceholder ? pageContent.otherInstitutionNamePlaceholder : 'E.g. Copenhagen Business College'"
            class="form-control form-control-lg"
            v-model="formData.otherEducationalInstitution"
            required
          />
        </div>
        <div
          class="form-group text-left"
          v-if="
            formData.institution &&
            universityLoaded &&
            formData.institution.name !== 'Other'
          "
        >
          <label>{{
            pageContent && pageContent.formDegreeLabel
              ? pageContent.formDegreeLabel
              : "Your study degree? *"
          }}</label>
          <autocomplete
            ref="autocomplete"
            :search="searchArea"
            :required="+formData.hasCustomProgramme === 0"
            :disabled="+formData.hasCustomProgramme === 1"
            :placeholder="+formData.hasCustomProgramme === 1 ? 'Not on the list' :'Search and select'"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getResultValue"
            @keydown.enter.prevent
            @submit="setAreaForUser"
            v-bind:default-value="formData.area"
          ></autocomplete>
          <div class="custom-programme-div">
            <button @click.prevent="handleCustomProgram" class="btn my-button-right">Not on the list?</button>
          </div>
        </div>
        <!-- this is for custom area stuff -->
        <div
          class="form-group text-left"
          v-if="+formData.hasCustomProgramme === 1"
        >
          <label>{{
            pageContent && pageContent.otherStudyDegreeLabel
              ? pageContent.otherStudyDegreeLabel
              : "Your study degree? *"
          }}</label>
          <input
            type="text"
            :placeholder="pageContent && pageContent.otherStudyDegreePlaceholder ? pageContent.otherStudyDegreePlaceholder : 'E.g. Bsc in Software development'"
              class="form-control form-control-lg"
            v-model="formData.area"
            required
          />
        </div>
        <div
          class="form-group text-left"
          v-if="formData.hasCustomProgramme === 1"
        >
          <label for>{{
            pageContent && pageContent.studyLevel
              ? pageContent.studyLevel
              : "Your level of study? *"
          }}</label>
            <select
              v-model="formData.degree"
              class="form-control"
              required
            >
              <option
                v-for="(degree, index) in degreeTypes"
                :value="degree.degree_name"
                :key="index"
              >
                {{ degree.degree_name }}
              </option>
            </select>
        </div>
        <!-- this is for custom area stuff -->
        <div
          class="form-group text-left"
          v-if="formData.institution && formData.institution.name === 'Other'"
        >
          <label>{{
            pageContent && pageContent.otherStudyDegreeLabel
              ? pageContent.otherStudyDegreeLabel
              : "Your study degree? *"
          }}</label>
          <input
            type="text"
            :placeholder="pageContent && pageContent.otherStudyDegreePlaceholder ? pageContent.otherStudyDegreePlaceholder : 'E.g. Bsc in Software development'"
              class="form-control form-control-lg"
            v-model="formData.otherProgrammeName"
            required
          />
        </div>
        <div
          class="form-group text-left"
          v-if="formData.institution && formData.institution.name === 'Other'"
        >
          <label for>{{
            pageContent && pageContent.studyLevel
              ? pageContent.studyLevel
              : "Your level of study? *"
          }}</label>
            <select
              v-model="formData.degree"
              class="form-control"
              required
            >
              <option
                v-for="(degree, index) in degreeTypes"
                :value="degree.degree_name"
                :key="index"
              >
                {{ degree.degree_name }}
              </option>
            </select>
        </div>
        <div
          class="form-group text-left"
          v-if="
            formData.hasSubProgramme &&
            formData.institution.name === 'Roskilde University'
          "
        >
          <label>{{
            pageContent && pageContent.formSpecializationCourseOneLabel
              ? pageContent.formSpecializationCourseOneLabel
              : "Specialization course #1? *"
          }}</label>
          <autocomplete
            :search="searchSubProgramme"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getSubProgrammeValue"
            @keydown.enter.prevent
            @submit="setFirstSpecialization"
            v-bind="{ 'default-value': formData.specializationCourseOne }"
          ></autocomplete>
        </div>
        <div
          class="form-group text-left"
          v-if="
            formData.hasSubProgramme &&
            formData.institution.name === 'Roskilde University'
          "
        >
          <label>{{
            pageContent && pageContent.formSpecializationCourseTwoLabel
              ? pageContent.formSpecializationCourseTwoLabel
              : "Specialization course #2? *"
          }}</label>
          <autocomplete
            :search="searchSubProgramme"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getSubProgrammeValue"
            @submit="setSecondSpecialization"
            @keydown.enter.prevent
            v-bind="{ 'default-value': formData.specializationCourseTwo }"
          ></autocomplete>
        </div>
      </div>
      <div
        class=""
        v-if="
          (formData.institution && universityLoaded && formData.area) ||
          formData.institution.name == 'Other'
        "
      >
        <hr class="mt-4" />
        <div class="form-group text-left" v-if="false">
          <label>{{
            pageContent && pageContent.formCurrentSemesterLabel
              ? pageContent.formCurrentSemesterLabel
              : "Which semester are you in? *"
          }}</label>
          <select
            v-if="isBachelor && !isOther"
            v-model="formData.currentSemester"
            class="form-control"
            required
          >
            <option
              v-for="(semester, index) in [1, 2, 3, 4, 5, 6]"
              :key="index"
            >
              {{ semester }}
            </option>
          </select>
          <select
            v-if="isBachelor && isOther"
            v-model="formData.currentSemester"
            class="form-control"
            required
          >
            <option
              v-for="(semester, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              :key="index"
            >
              {{ semester }}
            </option>
          </select>
          <select
            v-if="!isBachelor && !isOther"
            v-model="formData.currentSemester"
            class="form-control"
            required
          >
            <option v-for="(semester, index) in [1, 2, 3, 4]" :key="index">
              {{ semester }}
            </option>
          </select>
        </div>
        <div class="form-group text-left">
          <label>{{
            pageContent && pageContent.formGraduatingSemesterLabel
              ? pageContent.formGraduatingSemesterLabel
              : "When do/did you graduate? *"
          }}</label>
          <div class="d-flex">
            <select
              v-model="formData.graduatingYear"
              class="form-control mr-2 w-200"
              required
            >
              <option disabled value="">Select year</option>
              <option v-for="(year, index) in years" :value="year" :key="index">
                {{ year }}
              </option>
            </select>
            <select
              v-model="formData.graduatingMonth"
              class="form-control w-200"
              required
            >
              <option disabled value="">Select month</option>
              <option v-for="(month, index) in months" :value="month" :key="index">
                {{ month }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="d-flex justify-content-between mt-5 bottom_button_group">
        <button
          class="btn btn-link Explorer-YourStudyBackground-Back"
          @click="handleBack"
        >
          <i class="fas fa-chevron-left"></i>
          {{
            pageContent && pageContent.formBackButton
              ? pageContent.formBackButton
              : "Back"
          }}
        </button>
        <button
          class="btn btn-primary Explorer-YourStudyBackground-Next w-200"
          :disabled="isUpdating || !universityLoaded"
        >
          <span
            v-if="isUpdating"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>
            {{
              pageContent && pageContent.formNextButton
                ? pageContent.formNextButton
                : "Next"
            }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

import ProgressBar from "../ProgressBar.vue";
import generalService from "../../services/generalService";

import json_semesters from "@/json/semesters.json";
import {
  calculateCurrentSemester,
  calculateSemester,
  calculateSession,
  checkIsGraduated,
} from "../../utils/utils";

const userId = localStorage.getItem("uid");

export default {
  name: "StudentEducation",

  components: {
    Autocomplete,
    ProgressBar,
  },

  props: ["handleSubmit", "progress", "handleBack"],

  data() {
    return {
      errorMessage: null,
      universityData: {},
      semesters: json_semesters,
      loading: false,
      universityLoaded: false,
      subProgrammeList: [],
      initial: 1, // only used to get the university if the user has filled in the details in the past
      formData: {
        institution: null,
        otherEducationalInstitution: null,
        otherProgrammeName: null,
        faculty: null,
        area: null,
        degree: null,
        currentSemester: null,
        graduatingSemester: null,
        graduatingMonth: null,
        graduatingYear: null,
        specializationCourseOne: null,
        specializationCourseTwo: null,
        hasSubProgramme: false,
        hasCustomProgramme: 0,
      },
      years: [
        2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
        2024, 2025, 2026, 2027, 2028,
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      degreeTypes: [],
    };
  },

  mounted: function () {
    //   window.scrollTo(0, 0);

    if (!this.universities) this.$store.dispatch("getUniversities");
    if (!this.$store.getters.studentData) {
      //todo hit the get api
      this.$store.dispatch("getStudentData", userId);
    }
    // if (this.formData.institution && this.formData.institution.uid) {
    //   this.getCurrentUniversityData();
    //   this.loading = false;
    // }
  },

  computed: {
    user() {
      return this.$store.getters.studentData;
    },
    universities: function () {
      if (this.$store.getters.universities) {
        return this.$store.getters.universities;
      }
      return false;
    },

    isLoading() {
      const vm = this;
      if (this.user && this.initial) {
        vm.formData.institution = this.user.institution;
        vm.formData.area = this.user.area;
        vm.formData.degree = this.user.degree;
        vm.formData.faculty = this.user.faculty;
        vm.formData.hasSubProgramme = this.user.hasSubProgramme;
        vm.formData.currentSemester = this.user.currentSemester;
        vm.formData.otherEducationalInstitution =
          this.user.otherEducationalInstitution;
        vm.formData.otherProgrammeName = this.user.otherProgrammeName;
        vm.formData.graduatingSemester =
          this.user.graduatingSemester ?? this.user.graduationSemester;
        vm.formData.graduatingYear = this.user.graduatingYear;
        vm.formData.graduatingMonth = this.user.graduatingMonth;
        vm.formData.specializationCourseOne = this.user.specializationCourseOne;
        vm.formData.specializationCourseTwo = this.user.specializationCourseTwo;
        vm.formData.hasCustomProgramme = this.user.hasCustomProgramme;
      }
      if (this.formData.institution && this.initial)
        this.getCurrentUniversityData();
      vm.initial = 0;
      return (
        this.$store.getters.userIsLoading ||
        this.$store.getters.generalIsLoading ||
        !this.user
      );
    },

    pageContent() {
      return this.$store.getters.explorerEducationContent;
    },

    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    isBachelor() {
      if (!this.formData.degree) return true;
      return !this.user.degree.toLowerCase().includes("master");

    },
    isMaster() {
      return this.formData.degree.includes("Master");
    },
    isOther() {
      if (!this.formData.degree) return false;
      return this.formData.degree.includes("HA");
    },
  },
  methods: {
    totalCleanup() {
      this.errorMessage = null;
      this.formData.secondaryArea = null;
      this.formData.area = null;
      this.formData.degree = null;
      this.formData.faculty = null;
      this.formData.specializationCourseOne = null;
      this.formData.specializationCourseTwo = null;
      this.universityLoaded = false;
      this.otherEducationalInstitution = null;
      this.otherEducationalInstitution = null;
      this.otherProgrammeName = null;
      this.formData.hasCustomProgramme = 0;
    },
    handleCustomProgram(){
      this.formData.hasCustomProgramme = this.formData.hasCustomProgramme == 1 ? 0 : 1
      this.$refs.autocomplete.value = ''; 
    },
    getCurrentUniversityData() {
      // if(total){
      //   this.formData.secondaryArea = null;
      //   this.formData.area = null;
      //   this.formData.specializationCourseOne = null;
      //   this.formData.specializationCourseTwo = null;
      //   this.universityLoaded = false;
      // } else{
      //   this.formData.secondaryArea = null;
      //   // this.formData.area = null;
      //   this.formData.specializationCourseOne = null;
      //   this.formData.specializationCourseTwo = null;
      //   this.universityLoaded = false;
      // }
      try {
        generalService
          .getUniversityData(this.formData.institution.uid)
          .then((res) => {
            this.universityData = res;
            this.degreeTypes = res.degrees
            if (this.formData.hasSubProgramme) {
              const userProgram = this.universityData.programmes.filter(
                (program) => program.programme_name === this.formData.area
              );
              this.subProgrammeList = userProgram[0].subProgrammesList;
            }
            this.universityLoaded = true;
          });
      } catch (error) {
        alert("err");
        //
      }

      // this.universityLoaded = true;
      // try {
      //   const res = await apiInstance.get(
      //     `getUniversityDataListing/${this.formData.institution.uid}`
      //   );
      //   if (res.status == 200) {
      //     // no idea wtf this data is now. Random naming with random data is what we have here
      //     this.universityData = res.data.payload;
      //     if (this.formData.hasSubProgramme) {
      //       const userProgram = this.universityData.programmes.filter(
      //         (program) => program.programme_name === this.formData.area
      //       );
      //       // console.log("🚀 ~ file: StudentEducation.vue:229 ~ getCurrentUniversityData ~ userProgram:", userProgram[0].subProgrammesList)
      //       this.subProgrammeList = userProgram[0].subProgrammesList;
      //     }
      //   }
      // } catch (error) {
      //   console.log('err');
      // }
    },
    searchArea(input) {
      // if(this.university.name == 'other') return
      const vm = this;
      this.formData.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.universityData.programmes.filter((area) => {
        return area.programme_name.toLowerCase().includes(input.toLowerCase());
      });
    },
    searchSubProgramme(input) {
      const vm = this;
      this.formData.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.subProgrammeList.filter((area) => {
        return area.subProgrammeName
          .toLowerCase()
          .includes(input.toLowerCase());
      });
    },
    getResultValue(result) {
      this.formData.customProgramme = false;
      return result.programme_name;
    },
    getSubProgrammeValue(result) {
      this.formData.customProgramme = false;
      return result.subProgrammeName;
    },
    setAreaForUser(area) {
      this.formData.hasSubProgramme = false;
      if (!area) return;
      if (area["subProgrammesList"]) {
        this.formData.hasSubProgramme = true;
        this.subProgrammeList = area["subProgrammesList"];
      }
      this.formData.area = area.programme_name;
      // now we get the students degree details once they select an area
      this.formData.degree = Object.values(this.universityData.degrees).filter(
        (item) => item.id === area.degree_id
      )[0]?.degree_name;
      this.formData.faculty = Object.values(
        this.universityData.faculties
      ).filter((item) => item.faculty_id === area.faculty_id)[0]?.faculty_name;
      this.$forceUpdate();
    },
    setFirstSpecialization(area) {
      this.formData.specializationCourseOne = area.subProgrammeName;
    },
    setSecondSpecialization(area) {
      this.formData.specializationCourseTwo = area.subProgrammeName;
    },
    submitForm() {
      let isGraduated;
      let currentSemester;
      if (!this.formData.institution) {
        this.errorMessage = "Please select institution";
        return;
      }
      if (this.isBachelor) {
        this.formData.currentDegree = "bachelor";
      } else {
        this.formData.currentDegree = "master";
      }
      if (this.formData.graduatingYear && this.formData.graduatingMonth) {
        // const calculatedGraduatingSemester = calculateSemester(
          this.formData.graduatingSemester = calculateSemester(
          this.formData.graduatingMonth,
          +this.formData.graduatingYear
        );
        // console.log(
        //   "🚀 ~ file: StudentEducation.vue:442 ~ submitForm ~ ya:",
        //   ya
        // );
        // just creating this but waiting on kaspar to confirm its usage
        // currentSemester = calculateCurrentSemester(
        //   this.formData.graduatingMonth,
        //   this.formData.graduatingYear
        // );
        isGraduated = checkIsGraduated(
          this.formData.graduatingMonth,
          this.formData.graduatingYear
        );
      }
      console.log(this.formData.graduatingSemester);
      this.$emit("handle-submit", "education", {
        ...this.formData,
        isGraduated,
        currentSemester,
      });
    },
  },
};
</script>

<style scoped>
.autocomplete-input {
  color: #495057 !important;
}

.autocomplete .autocomplete-result-list li {
  color: #495057 !important;
}
.my-button-right{
  font-size: 12px;
  color: #0a7380;
}
.my-button-right:focus {
  outline: none;
  box-shadow: none;
}
.custom-programme-div {
  width: 100%;
  text-align:right
}
</style>
