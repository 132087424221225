<template>
  <div class="progress-linear">
    <div class="d-flex align-items-center">
      <div class="dots_block">
        <span class="block_box"></span>
        <span class="block_box"></span>
        <span class="block_box"></span>
      </div>
      <div class="barWrapper">
        <h6 class="disabled-text content-spacing">Investigation period</h6>
        <div class="progress">
        </div>
      </div>
      <div class="barWrapper">
        <h6 class="content-spacing">Writing period</h6>
        <div class="progress-bar">
        </div>
      </div>
      <div class="barWrapper">
        <h6 class="disabled-text content-spacing">Thesis hand-in and exam</h6>
        <div class="progress">
        </div>
      </div>
      <!-- <div class="width-100">Thesis hand-in</div> -->
    </div>
    <!-- <div>Thesis-contract hand-in</div> -->
  </div>
</template>

<script>
export default {
  name: "thesisProgressBar",

}
</script>

<style scoped>
.tooltip {
  position: relative;
  float: right;
}

/* @media (min-width: 767px) {
.progress-spacing {
  margin: 70px auto;
  width: 487px;
}
} */
.progress-spacing {
  margin: 50px auto;
  max-width: 410px;
}

.progress-linear {
  margin: 50px auto;
  max-width: 650px;
}

.content-spacing {
  padding-bottom: 10px;
}

.barWrapper {
  position: relative;
}

.barWrapper h6 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

/* @media (max-width: 600px) {
.progress-spacing {
  margin-bottom: 20%
}
} */
.tooltip>.tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: #5a2871;
  font-weight: bold;
  font-size: 13px;
}

.active-text {
  color: #5a2871 !important;
}

.popOver+.tooltip>.tooltip-arrow {
  /* border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid #eebf3f; */
}

section {
  margin: 100px auto;
  height: 1000px;
}

.progress {
  /* border-radius: 5px; */
  overflow: visible;
  height: 6px;
  position: relative;
}

.active_second {
  color: #8a2372 !important;
}

.progress-bar {
  background: #bc1e73;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  overflow: visible;
  height: 6px;
  /* border-radius: 5px; */
}

.progress {
  background-color: #dbdbdb !important;
}

.width-100 {
  width: 100%;
}

.icon_wrap {
  position: relative;
  color: #dbdbdb;
  font-weight: 600;
}

.icon_text {
  margin: 0;
  position: absolute;
  margin-top: 6px;
  margin-left: -6px;
  color: #dbdbdb;
  white-space: nowrap;
}

.barWrapper {
  width: 100%;

}

.barWrapper h6 {
  color: #bc1e73;
}

.tooltip_box {
  position: absolute;
  bottom: 25px;
}

.custom_tooltip {
  color: #fff;
  /* position: absolute;
    top: 14%; */
  background-color: #5A2871;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.arrows-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #5a2871;
  position: absolute;
  left: 39%;
}

.disabled-text {
  color: #dbdbdb !important;
  width: 100% !important;
}</style>
