<template>
  <div class="col-12 user-form">
    <div v-if="loadingAllInterests || loadingUserInterests" class="loader">Loading...</div>
    <form v-else @submit.prevent="validateForm">
      <h1 v-if="isZealandFlow" class="text-uppercase common-text">Dine interesseområder </h1>
      <h1 v-else class="text-uppercase common-text">
        {{ pageContent && pageContent.formHeader ? pageContent.formHeader : 'YOUR RESEARCH INTERESTS' }}</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 v-if="isZealandFlow" class="welcome-desc font-weight-normal">Hvad er dine interesseområder? Klik for at vælge.
      </h4>
      <h4 v-else class="welcome-desc font-weight-normal">
        {{ pageContent && pageContent.formSubHeader ? pageContent.formSubHeader : 'Which research area(s) interest you? Click to select.' }}
      </h4>
      <div class="main-card-box">
        <div class="card card-custom" v-bind:class="{
          active_card:
            researchInterests && researchInterests.includes(item.id),
        }" v-for="item in categoryList" :key="item.id" @click="toggleSelect(item.id)">
          <div class="card-body d-flex custom-card-body">
            <div class="" v-if="researchInterests && researchInterests.includes(item.id)">
              <img class="image-list" v-bind:src="item.lite_image" v-bind:alt="item.category_name" />
            </div>
            <div class="" v-else>
              <img class="image-list" v-bind:src="item.image" v-bind:alt="item.category_name" />
            </div>
            <h6 class="card-subtitle text-muted pl-2" v-bind:class="{
              active_card_text:
                researchInterests && researchInterests.includes(item.id),
            }">
              {{ item.category_name }}
            </h6>
          </div>
        </div>
      </div>
      <div class="alert alert-danger mt-3" v-if="error && researchInterests.length === 0">
        {{ error }}
      </div>
      <div class="d-flex justify-content-between mt-5 button_group">
        <button class="btn btn-link Explorer-YourResearchInterests-Back" @click="handleBack">
          <i class="fas fa-chevron-left"></i>
          <template v-if="isZealandFlow">
            Tilbage
          </template>
          <template v-else>
            Back
          </template>
        </button>
        <button class="btn btn-primary Explorer-YourResearchInterests-Next w-200" :disabled="isUpdating">
          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>
            <template v-if="isZealandFlow">
              Næste
            </template>
            <template v-else>
              {{ pageContent && pageContent.formNextButton ? pageContent.formNextButton : 'Next' }}
            </template>
          </span>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import apiInstance from '../../axios/axios';
import { base64ToString } from '../../utils/utils';
import ProgressBar from '../ProgressBar.vue';

let userId = localStorage.getItem('uid')
userId = base64ToString(userId)

export default {
  name: 'StudentResearch',
  data() {
    return {
      error: null,
      researchInterests: []
    };
  },
  components: {
    ProgressBar,
  },
  props: ['progress', 'handleBack'],
  async mounted() {
    window.scrollTo(0, 0);
    if (!this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId);
    }
    if (!this.$store.getters.researchInterests || this.$store.getters.researchInterests.length === 0) {
      this.$store.dispatch('getResearchInterests')
    }
  },
  computed: {
    loadingUserInterests() {
      const vm = this;
      let res = true
      vm.researchInterests = this.$store.getters.studentResearchInterests ? this.$store.getters.studentResearchInterests : []
      if (vm.researchInterests) {
        res = false
      }
      return res
    },
    loadingAllInterests() {
      return this.$store.getters.generalIsLoading || this.$store.getters.dynamicIsLoading
    },
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    categoryList() {
      return this.$store.getters.researchInterests;
    },
    pageContent() {
      return this.$store.getters.explorerResearchContent
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    // userInterests() {
    //   const vm = this;
    //   vm.researchInterests = this.$store.getters.studentResearchInterests ? this.$store.getters.studentResearchInterests : []
    //   return null
    // },
  },
  methods: {
    toggleSelect(id) {
      if (this.researchInterests.indexOf(id) > -1) {
        this.researchInterests.splice(this.researchInterests.indexOf(id), 1);
      } else {
        this.researchInterests.push(id);
      }
    },
    validateForm() {
      if (this.researchInterests.length === 0) {
        return (this.error = this.pageContent && this.pageContent.researchErrorMessage ? this.pageContent.researchErrorMessage : 'Please select a research area');
      }
      this.$emit('handle-submit', 'research', this.researchInterests);
    },
  },
};
</script>

<style scoped>
.image-list {
  width: 70px;
  /* height: 100px; */
}

.main-card-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  width: 100%;
  justify-content: center;
}

.card-custom {
  box-shadow: none;
  width: 24%;
  border-radius: 10px;
  background-color: #dbdbdb;
}

.card {
  margin-bottom: 10px;
  cursor: pointer;
}

.card-body {
  align-items: center;
}

.card-subtitle {
  margin: 5px 5px !important;
  color: #9898a9 !important;
  font-weight: 600;
  text-align: left;
  font-size: 14px !important;
  text-transform: uppercase;
  text-overflow: wrap;
}

.card-custom:hover {
  /* border: 1px solid #bc1e73 !important; */
  /* background: #BC1E73; */
}

.card-custom:hover .custom-card-body h4 {
  /* color: #fff; */
}

.active_card {
  background: #bc1e73;
  border: 1px solid #bc1e73 !important;
}

.active_card_text {
  color: #fff !important;
  font-weight: 600;
  font-size: 14px !important;
  text-transform: uppercase;
}

@media (max-width: 390px) {

  /* .image-list {
    width: 30px;
  } */
  .card-custom {
    width: 100% !important;
    margin-bottom: 12px;
  }
}

/* @media (max-width: 767px) { */
@media (max-width: 1200px) {

  /* Divyansh will help figure this part out */
  .image-list {
    width: 35px;
  }

  .card {
    border: none !important;
  }

  .footer-image-list {
    flex-direction: row;
    position: relative;
  }

  .main-card-box {
    justify-content: space-between;
  }

  .card-custom {
    width: 48%;
    margin-bottom: 12px;
  }

  .custom-card-body {
    padding: 10px;
    /* background: #efefef; */
    justify-content: start;
  }

  .card-subtitle {
    font-size: 12px !important;
    margin: 5px 0px 5px 3px !important;
  }
}
</style>
