<template>
  <div class="col-12 user-form">
    <div class="pj-loading" v-if="isLoading"><i class="fas fa-circle-notch fa-spin"></i></div>
    <form v-else @submit.prevent="validateForm">
      <h1 class="text-uppercase common-text"> {{pageContent && pageContent.formHeader ? pageContent.formHeader : 'WELCOME TO EXCELERATE'}}</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="welcome-desc font-weight-normal">
        {{pageContent && pageContent.formSubHeader ? pageContent.formSubHeader : 'Tell us a bit more about yourself to get started using Excelerate.' }}
      </h4>
      <div class="form-group text-left">
        <label for> {{pageContent && pageContent.formFirstNameLabel ? pageContent.formFirstNameLabel : 'First name'}}</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="formData.firstName"
          autofocus
          required
        />
      </div>
      <div class="form-group text-left">
        <label for>{{pageContent && pageContent.formLastNameLabel ? pageContent.formLastNameLabel : 'Last name'}}</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="formData.lastName"
          required
        />
      </div>
      <div>
        <div class="form-group text-left">
          <label for> {{pageContent && pageContent.formPhoneNumberLabel ? pageContent.formPhoneNumberLabel : 'Phone number'}}</label>
          <input
            type="phone"
            class="form-control form-control-lg"
            v-model="formData.phoneNumber"
            :placeholder="pageContent && pageContent.formPhoneNumberPlaceholder ? pageContent.formPhoneNumberPlaceholder : 'E.g. 11223344'"
            required
          />
        </div>
        <div class="form-group text-left">
          <label for>{{pageContent && pageContent.formLanguageLabel ? pageContent.formLanguageLabel : 'You speak fluently'}}</label>
          <!-- <select v-model="formData.language" required class="form-control">
            <option value="null" disabled>Select from list</option>
            <option
              v-for="(language, index) in languages"
              :key="index"
              :value="language.key"
            >
              {{ language.name }}
            </option>
          </select> -->
          <multiselect
            multiple
            v-model="formData.language"
            :options="languages"
            :showLabels="false"
            :searchable="false"
            :allow-empty="false"
            deselect-label="Can't remove this value"
            placeholder="Select one"
            track-by="name"
            label="name"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{
              option.name
            }}</template>
          </multiselect>
          <div class="alert alert-danger mt-3" v-if="noLanguage">
            {{pageContent && pageContent.formLanguagePlaceholder ? pageContent.formLanguagePlaceholder : 'Please select at least one language.'}}
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-end bottom_button_group align-items-center"
      >
        <button
          class="btn btn-primary Explorer-WelcomeToExcelerate-Next mt-5 w-200"
          :disabled="isUpdating"
        >
          <span
            v-if="isUpdating"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span
          >
          <span v-else>
            {{pageContent && pageContent.formNextButton ? pageContent.formNextButton : 'Next'}}
            </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ProgressBar from '../ProgressBar.vue';
import { base64ToString } from '../../utils/utils';

let userId = localStorage.getItem('uid')
userId = base64ToString(userId)

export default {
  name: 'StudentWelcome',
  props: ['handleSubmit', 'progress'],
  components: { ProgressBar, Multiselect, },
  data: function () {
    return {
      noLanguage: false,
      languages: [
        { name: 'English', key: 'en' },
        { name: 'Dansk', key: 'da' },
        { name: 'German', key: 'ge' },
        { name: 'Norwegian', key: 'nw' },
        { name: 'Swedish', key: 'sw' },
      ],
      formData: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        language: null,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    // todo check if don't have any user data and the request the data (maybe if status is new don't request it at all?)
    if(!this.$store.getters.studentData) {
    //   //todo hit the get api
      this.$store.dispatch('getStudentData', userId)
    }
    if(!this.$store.getters.signupData) this.$store.dispatch('setSignupData','Student')
  },
  computed: {
    user(){
      return this.$store.getters.studentData
    },
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    isLoading(){
      const vm = this;
      if (vm.user) {
        vm.formData.firstName = vm.user.firstName
        vm.formData.lastName = vm.user.lastName
        vm.formData.phoneNumber = vm.user.phoneNumber
        vm.formData.language = vm.user.userLanguage
      }
      return vm.$store.getters.userIsLoading || this.$store.getters.generalIsLoading;
    },
    pageContent() {
      return this.$store.getters.explorerBasicContent
    }
  },
  methods: {
    validateForm() {
      if (!this.formData.language || this.formData.language.length === 0) {
        this.noLanguage = true;
      } else {
        // const languages = this.language.map((l) => l.key).join(',')
        this.$emit('handle-submit', 'basic details', this.formData)
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.text-left label {
  text-align: left;
}
.dots_block {
  display: flex;
  column-gap: 1px;
}
.icon_box {
  border: 1px solid #eee;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 50%;
}
.icon_box .fa-search {
  color: #aaa;
}
.block_box {
  width: 7px;
  height: 7px;
  background-color: #dbdbdb;
  display: block;
  border-radius: 5px;
}
.block_box_completed {
  width: 7px;
  height: 7px;
  background-color: #5a2871;
  display: block;
  border-radius: 5px;
}
.tooltip {
  position: relative;
  float: right;
}
.tooltip > .tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: rgb(23, 44, 66);
  font-weight: bold;
  font-size: 13px;
}
.popOver + .tooltip > .tooltip-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #eebf3f;
}

.progress {
  border-radius: 0;
  overflow: visible;
}
.progress-bar {
  background: rgb(23, 44, 60);
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
}

/* @media (max-width: 767px) {
  .
} */
</style>
<style>
.multiselect__input {
  color: #5a2871;
}
.multiselect__option--highlight {
  background: #bd1a77;
  outline: none;
  color: #fff;
}
.multiselect {
  color: #5a2871;
  font-size: 14px;
  background: #fff;
}
.multiselect,
.multiselect__single {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}
.multiselect__single {
  margin-bottom: 0px;
}
.multiselect__tags {
  font-family: 'Poppins', sans-serif;
  color: #5a2871;
  font-size: 14px;
  border: 1px solid #dfd6df !important;
  border-radius: 4px;
  padding: 6px 20px 0 8px;
  min-height: 35px !important;
}
.multiselect__tag {
  color: #5a2871 !important;
  background: transparent !important;
}
.multiselect__tag-icon:after {
  color: #5a2871 !important;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: transparent !important;
}
.multiselect__option--selected.multiselect__option--highlight {
  background-color: #5a2871;
}
.multiselect__placeholder {
  margin-bottom: 4px;
}
</style>
