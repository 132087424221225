<template>
  <div v-if="isLoaded">
    <studentHeader />
    <div class="user-flow-logo">
      <div v-if="step !== 6" class="user-splash"></div>
      <div class="alert alert-success container-narrow mx-auto banner" v-show="showVerificationMessage && step === 6"
        role="alert">
        Your account has been verified
      </div>
      <div class="alert alert-danger container-narrow mx-auto banner" v-if="errorMessage" role="alert">
        {{ errorMessage }}
      </div>
      <div class="user-flow" :style="{ maxWidth: width }">
        <div class="row">
          <!-- We are going to handle steps conditionally in this component based on a step counter which we will increment a  -->
          <StudentWelcome v-if="step === 1" :progress="17" @handle-submit="handleSubmit">
          </StudentWelcome>
          <StudentEducation v-if="step === 2" :progress="33" @handle-submit="handleSubmit" :handleBack="handleBack">
          </StudentEducation>
          <StudentResearch v-if="step === 3" :progress="50" :error="error" @handle-submit="handleSubmit"
            :handleBack="handleBack"></StudentResearch>
          <StudentThesis v-if="step === 4" :progress="75" :error="error" @handle-submit="handleSubmit"
            :handleBack="handleBack"></StudentThesis>
          <StudentVerification v-if="step === 5" :progress="83" :verifyMessageError="verifyMessageError"
            @handle-submit="handleSubmit" :handleBack="(e) => handleBack(e, 'skipThesis')"
            :verifyMessageSuccess="verifyMessageSuccess"></StudentVerification>
          <StudentDidYouKnow v-if="step === 6" :userDetails="userDetails" :progress="100" :handleSubmit="handleSubmit"
            :handleBack="handleBack">
          </StudentDidYouKnow>
          <student-explorer v-if="step === 7" :progress="0" :handleSubmit="handleSubmit"
            :handleBack="handleBack"></student-explorer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentWelcome from '../../components/student/StudentWelcome.vue';
import StudentEducation from '../../components/student/StudentEducation.vue';
import StudentResearch from '../../components/student/StudentResearch.vue';
import StudentThesis from '../../components/student/StudentThesis.vue';
import StudentVerification from '../../components/student/StudentVerification.vue';
import StudentDidYouKnow from '../../components/student/StudentDidYouKnow.vue';
import StudentHeader from '../../components/student/StudentHeader.vue';
import StudentExplorer from '../../components/student/StudentExplorer.vue';

import { mapState } from 'vuex';
import { base64ToString } from '../../utils/utils';

const user = base64ToString(localStorage.getItem('uid'))

export default {
  name: 'StudentRegister',
  components: {
    StudentWelcome,
    StudentEducation,
    StudentResearch,
    StudentThesis,
    StudentVerification,
    StudentDidYouKnow,
    StudentHeader,
    StudentExplorer,
  },
  data: function () {
    return {
      userDetails: {},
      userInfo: {},
      verifyMessageError: null,
      error: null,
      step: 1, // always set to 1 by default
      verifyMessageSuccess: null,
      showVerificationMessage: true,
    };
  },
  updated() {
    var vm = this;
    if (this.showVerificationMessage) {
      setTimeout(() => {
        this.showVerificationMessage = false
      }, 3000);
    }
  },
  watch: {
    errorMessage() {
      if (this.errorMessage) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.$store.dispatch('clearUserError')
        }, 3000);
      }
    },
  },
  computed: {
    ...mapState(['user']),
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    width() {
      switch (this.step) {
        case 3:
          return '100%';
        case 4:
          return '100%';
        case 6:
          return '70%';
        default:
          return '50%';
      }
    },
    errorMessage() {
      return this.$store.getters.userErrorMessage;
    },
    isLoaded() {
      var vm = this;
      var result = false;
      if (vm.user.student) {
        vm.userInfo = vm.user.student;
      }
      return true;
    },
  },
  methods: {
    previousStep: function () {
      this.step--;
    },
    nextStep: function () {
      this.step++;
    },
    handleSubmit(step, data) {
      const vm = this;
      vm.error = null;
      switch (step) {
        case 'basic details':
          vm.$store
            .dispatch('updateStudent', { ...vm.userInfo, ...data })
            .then(() => this.nextStep());
          break;
        case 'education':
          vm.$store
            .dispatch('updateStudent', { ...vm.userInfo, ...data })
            .then(() => this.nextStep());
          break;
        case 'research':
          vm.$store.dispatch('updateStudent', { ...vm.userInfo, researchInterests: data }).then(() => {
            if (vm.isZealandFlow && !vm.userInfo.isVerified) {
              this.step = this.step + 2
            } else if (vm.isZealandFlow && vm.userInfo.isVerified) {
              this.step = this.step + 4
            }else if (vm.userInfo.isGraduated && vm.userInfo.isVerified) {
              //? if user has already graduated and is verified, we skip the next two steps (thesis and verification)
              this.step = this.step + 4
            } else if (vm.userInfo.isGraduated) {
              //? if user has already graduated and is verified, we skip the next step which is the thesis step
              this.step = this.step + 2
            } else {
              this.nextStep()
            }
          });
          break;
        case 'thesis':
          vm.$store.dispatch('updateStudent', { ...vm.userInfo, ...data, currentProgress: 0 }).then(() => {
            // if(vm.userInfo.isVerified && vm.isZealandFlow) {
            //   this.step = this.step + 3
            // } else 
            if (vm.userInfo.isVerified) {
              this.step = this.step + 2
            } else {
              this.nextStep();
            }
          })
          break;
        case 'verification':
          this.verifyMessageSuccess = null;
          this.$store.dispatch('verifyStudent', { otp: data }).then((r) => {
            if (!this.errorMessage) {
              // if (vm.isZealandFlow) {
              //   this.step = this.step + 2;
              // } else 
              if (vm.userInfo.isGraduated) {
                //? if graduated, we skip one step
                this.step = this.step + 2;
              } else if(this.isZealandFlow) {
                this.step = 7
              } else {
                this.step = this.step+1
              }
              localStorage.setItem('u-verified', 1)
              this.showVerificationMessage = true
            } else {
              localStorage.setItem('u-verified', 0)
            }
          }).catch(() => {
            localStorage.setItem('u-verified', 0)
          })
          break;
        case 'did you know':
          localStorage.removeItem('notSignedUp')
          vm.$store.dispatch('updateUserDetails', { ...vm.userDetails, currentProgress: 0 })
          this.nextStep();
          break;
        default:
          break;
      }
    },
    handleBack(event, skipStep) {
      event.preventDefault();
      if (skipStep) {
        this.step = this.step - 2
      } else {
        this.previousStep();
      }
      this.verifyMessageSuccess = null;
      this.verifyMessageError = null;
    },
  },
};
</script>

<style>
.btn-outline {
  border: 1px solid #d22d89;
  color: #d22d89;
  padding: 0.4rem 4rem;
  font-size: 16px;
  font-weight: 600;
}

.user-flow .user-form form {
  padding: 50px 30px 30px 30px !important;
}

.w-200 {
  width: 200px;
  height: 40px;
}

.form-control {
  color: #495057 !important;
}

.welcome-desc {
  margin-bottom: 50px;
}

.user-flow {
  border-radius: 10px !important;
}

@media (max-width: 767px) {
  .header-text {
    font-size: 24px !important;
  }

  .user-flow .user-form form {
    padding: 0 !important;
  }

  .common-text {
    font-size: 24px;
    margin-bottom: 15px;
    margin-bottom: 46px;
  }

  .progress-spacing {
    margin: 25px 0px 50px !important;
    width: 100%;
  }

  #app #app-master #page {
    background-image: none !important;
  }
}

@media (max-width: 500px) {
  .bottom_button_group {
    position: sticky;
    bottom: 15px;
    left: 15px;
    right: 15px;
    margin: 0 auto;
    max-width: 100%;
  }

  .alert-success {
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .banner {
    display: none;
  }
}
</style>

<style scoped>
h4 {
  font-size: 2.125rem;
}

/* .user-flow{
  max-width: 100%;
} */
.user-flow-logo {
  padding: 60px 0 40px 0;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .user-flow {
    max-width: 100% !important;
  }

  .user-splash {
    display: none;
  }

  .user-flow {
    border: 0;
    box-shadow: none;
  }

  .user-flow-logo {
    padding: 40px 0 40px 0;
    margin-top: 0px;
  }
}
</style>
