<template>
  <div class="col-12 user-form" ref="content">
    <form @submit.prevent="validateForm">
      <h1 class="text-uppercase common-text">
        {{ pageContent && pageContent.formHeader ? pageContent.formHeader : 'YOUR THESIS PROGRESS' }}</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <div class="">
        <h4 class="font-weight-normal">When do/did you start the <span class="pink-color">writing period</span> of your
          thesis project?</h4>
        <!-- <thesis-progress-bar isZealandFlow="isZealandFlow"/> -->
        <thesis-progress-bar />
        <div class="form-group text-center main_form my-5">
          <label class="pink-color" for>
            {{ pageContent && pageContent.formFirstNameLabel ? pageContent.formFirstNameLabel : 'Writing start Year / Month' }}</label>

          <div class="start_year_flex">
            <select class="form-control" required v-model="anticipatedStartThesisYear">
              <option disabled value="">Select year</option>
              <option v-for="(year, index) in years" :key="index">
                {{ year }}
              </option>
            </select>
            <select class="form-control" v-model="anticipatedStartThesisMonth" required>
              <option disabled value="">Select month</option>
              <option v-for="(month, index) in months" :key="index">
                {{ month }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <template>
        <h4 class="welcome-desc font-weight-normal" v-if="anticipatedStartThesisMonth && anticipatedStartThesisYear">
          {{ pageContent && pageContent.formHeader ? pageContent.formHeader : 'Tell us a bit more about your thesis project progress' }}
        </h4>
        <h4 class="welcome-desc font-weight-normal" v-if="anticipatedStartThesisMonth && anticipatedStartThesisYear">Where
          are you today? Click below.</h4>

        <div class="container stepper-container" v-if="anticipatedStartThesisMonth && anticipatedStartThesisYear">
          <div class="mb-3 mt-3">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
              <a v-on:click="setSelected(1)" class="step" v-bind:class="{ completed: thesisStatus === 1 }">
                <div class="step-icon-wrap">
                  <div class="step-icon icon_1"></div>
                </div>
                <div class="form-info step-above">
                  <div class="arrow-up"></div>
                  <div>
                    <h4 class="step-title">
                      {{ pageContent && pageContent.formInspirationHeader ? pageContent.formInspirationHeader : 'INSPIRATION' }}
                    </h4>
                    <p>
                      {{ pageContent && pageContent.formInspirationSubHeader ? pageContent.formInspirationSubHeader : 'I\'m currently searching for inspiration and/or guidance for my thesis project.' }}
                    </p>
                  </div>
                </div>
              </a>
              <div v-on:click="setSelected(2)" class="step" v-bind:class="{ completed: thesisStatus === 2 }">
                <div class="step-icon-wrap">
                  <div class="step-icon icon_2"></div>
                </div>

                <!-- this is written only for visual purposes to show equal verticaly space between steps -->
                <div class="form-info step-above" style="opacity: 0">
                  <div class="arrow-up"></div>
                  <div>
                    <h4 class="step-title">
                      {{ pageContent && pageContent.formInspirationHeader ? pageContent.formInspirationHeader : 'INSPIRATION' }}
                    </h4>
                    <p>
                      {{ pageContent && pageContent.formInspirationSubHeader ? pageContent.formInspirationSubHeader : 'I\'m currently searching for inspiration and/or guidance for my thesis project.' }}
                    </p>
                  </div>
                </div>
              </div>
              <a v-on:click="setSelected(3)" class="step" v-bind:class="{ completed: thesisStatus === 3 }">
                <div class="step-icon-wrap">
                  <div class="step-icon icon_3"></div>
                </div>
                <div class="form-info">
                  <div class="arrow-up"></div>
                  <div>
                    <h4 class="step-title">
                      {{ pageContent && pageContent.formIdeasHeader ? pageContent.formIdeasHeader : 'IDEAS ARE FORMING' }}
                    </h4>
                    <p>
                      {{ pageContent && pageContent.formIdeasSubHeader ? pageContent.formIdeasSubHeader : 'Thesis ideas are starting to form but I\'m not settled on a topic for my thesis project yet.' }}
                    </p>
                  </div>
                </div>
              </a>
              <div v-on:click="setSelected(4)" class="step" v-bind:class="{ completed: thesisStatus === 4 }">
                <div class="step-icon-wrap">
                  <div class="step-icon icon_4"></div>
                </div>
                <!-- this is written only for visual purposes to show equal verticaly space between steps -->
                <div class="form-info step-above" style="opacity: 0">
                  <div class="arrow-up"></div>
                  <div>
                    <h4 class="step-title">INSPIRATION</h4>
                    <p>
                      I'm currently searching for inspiration and/or guidance for
                      my thesis project.
                    </p>
                  </div>
                </div>
              </div>
              <a v-on:click="setSelected(5)" class="step" v-bind:class="{ completed: thesisStatus === 5 }">
                <div class="step-icon-wrap last">
                  <div class="step-icon icon_5"></div>
                </div>
                <div class="form-info">
                  <div class="arrow-up"></div>
                  <div>
                    <h4 class="step-title">
                      {{ pageContent && pageContent.formReadyHeader ? pageContent.formReadyHeader : 'READY TO BE MATCHED' }}
                    </h4>
                    <p>
                      {{ pageContent && pageContent.formReadySubHeader ? pageContent.formReadySubHeader : 'I\'m settled on a thesis topic and ready to be matched with relevant companies.' }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="alert alert-danger mt-3" v-if="error && !thesisStatus">
          {{ error }}
        </div>
      </template>
      <div class="d-flex justify-content-between mt-5" :class="{
        bottom_button_group:
          !isContentLong && !error && !thesisStatus,
      }">
        <button class="btn btn-link Explorer-YourThesisProcess-Back" @click="handleBack">
          <i class="fas fa-chevron-left"></i>
          {{ pageContent && pageContent.formBackButton ? pageContent.formBackButton : 'Back' }}
        </button>
        <button class="btn btn-primary Explorer-YourThesisProcess-Next w-200" :disabled="isUpdating">
          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else> {{ pageContent && pageContent.formNextButton ? pageContent.formNextButton : 'Next' }} </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import ProgressBar from '../ProgressBar.vue';
import ThesisProgressBar from '../thesisProgressBar.vue';

import { base64ToString, calculateEstimatedSemester } from '../../utils/utils';
import { calculateSemester } from '../../utils/utils';

let userId = base64ToString(localStorage.getItem('uid'))
// userId = base64ToString(userId)

export default {
  name: 'StudentThesis',
  data() {
    return {
      isContentLong: false,
      thesisStatus: null,
      anticipatedStartThesisYear: null,
      anticipatedStartThesisMonth: null,
      anticipatedStartThesisSemester: null,
      anticipatedStartInternshipMonth: null,
      anticipatedStartInternshipYear: null,
      anticipatedStartInternshipSemester: null,
      error: null,
      years: [2024, 2025, 2026, 2027, 2028, 2029, 2030],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    };
  },
  components: {
    ProgressBar,
    ThesisProgressBar,
  },
  props: ['progress', 'handleBack'],

  mounted() {
    window.scrollTo(0, 0);
    const contentHeight = this.$refs.content.offsetHeight + 80; // get height of your content element
    const viewportHeight = window.innerHeight; // get height of device viewport
    if (contentHeight > viewportHeight) {
      this.isContentLong = true;
      return;
    }
    this.isContentLong = false;
    //? setting the page/form data here
    if (!this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId).then(() => {
        this.thesisStatus = +this.$store.getters.studentThesisStatus
        this.anticipatedStartThesisMonth = this.$store.getters.studentData.anticipatedStartThesisMonth
        this.anticipatedStartThesisYear = this.$store.getters.studentData.anticipatedStartThesisYear
        this.anticipatedStartInternshipMonth = this.$store.getters.studentData.anticipatedStartInternshipMonth
        this.anticipatedStartInternshipYear = this.$store.getters.studentData.anticipatedStartInternshipYear
      });
    } else {
      this.thesisStatus = +this.$store.getters.studentThesisStatus
      this.anticipatedStartThesisMonth = this.$store.getters.studentData.anticipatedStartThesisMonth
      this.anticipatedStartThesisYear = this.$store.getters.studentData.anticipatedStartThesisYear
      this.anticipatedStartInternshipMonth = this.$store.getters.studentData.anticipatedStartInternshipMonth
      this.anticipatedStartInternshipYear = this.$store.getters.studentData.anticipatedStartInternshipYear
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.userIsLoading || this.$store.getters.dynamicIsLoading;
    },
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    pageContent() {
      return this.$store.getters.explorerThesisContent
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
  methods: {
    setSelected(id) {
      this.thesisStatus = id;
    },
    validateForm() {
      if ((!this.thesisStatus || this.thesisStatus < 1) && !this.isZealandFlow) {
        this.error = this.pageContent && this.pageContent.thesisErrorMessage ? this.pageContent.thesisErrorMessage : 'Please select how far you are on your thesis journey';
        return;
      }
      if (this.anticipatedStartThesisMonth && this.anticipatedStartThesisYear && !this.isZealandFlow) {
        this.anticipatedStartThesisSemester = calculateEstimatedSemester(this.anticipatedStartThesisMonth, this.anticipatedStartThesisYear)
      }
      if (this.anticipatedStartInternshipMonth && this.anticipatedStartInternshipYear && this.isZealandFlow) {
        this.anticipatedStartInternshipSemester = calculateEstimatedSemester(this.anticipatedStartInternshipMonth, this.anticipatedStartInternshipYear)
      }
      if (this.isZealandFlow) {
        const thesisData = {
          anticipatedStartInternshipMonth: this.anticipatedStartInternshipMonth,
          anticipatedStartInternshipYear: this.anticipatedStartInternshipYear,
          anticipatedStartInternshipSemester: this.anticipatedStartInternshipSemester,
        }
        this.$emit('handle-submit', 'thesis', thesisData)
      } else {
        const thesisData = {
          anticipatedStartThesisMonth: this.anticipatedStartThesisMonth,
          anticipatedStartThesisYear: this.anticipatedStartThesisYear,
          anticipatedStartThesisSemester: this.anticipatedStartThesisSemester,
          thesisStatus: +this.thesisStatus
        }
        this.$emit('handle-submit', 'thesis', thesisData)

      }
    },
  },
};
</script>

<style scoped>
.stepper-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.steps .step {
  display: block;
  width: 100%;
  /* margin-bottom: 35px; */
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 30px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 1px;
  margin-top: -1px;
  background-color: #5a2871;
  content: '';
  z-index: 1;
}

.steps .step.completed .step-icon:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.steps .step:hover {
  text-decoration: none;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border: 4px solid #5a2871;
  color: #fff;
  border-radius: 50%;
  background-color: #fff;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
  cursor: pointer;
}

.steps .step .step-title {
  margin-top: 10px;
  color: #000;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
}

.form-info p {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #5a2871;
}

.steps .step.completed .step-icon {
  background: #bc1e73;
  color: #fff;
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid black;
  margin: auto;
  margin-top: 20px;
}

.main_form {
  max-width: 450px;
  margin: auto;
}

.start_year_flex {

  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .steps .step {
    display: flex;
  }

  .steps .step .step-icon-wrap {
    width: auto;
    height: 50px;
  }

  .steps .step .step-icon-wrap::before,
  .steps .step .step-icon-wrap::after {
    width: 1px;
    height: 100%;
    margin-top: -3px;
  }

  .steps .step .step-icon-wrap::after {
    left: 50%;
  }

  .steps .step .step-icon-wrap::before {
    left: 50%;
  }

  .form-info {
    display: flex;
    margin-top: 2px;
    column-gap: 8px;
  }

  .steps .step .step-title {
    margin-top: 0;
    text-align: left;
  }

  .form-info p {
    text-align: left;
    margin-bottom: 0;
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid #000;
    margin: 8px 0 0 0;
  }

  .last:before {
    display: none !important;
  }

  .mobile_error {}
}
</style>
