<template>
  <div class="col-12 user-form user-splash mobile_body">
    <form class="text-light" @submit.prevent="handleSubmit('did you know')">
    <div
      class="alert alert-success container-narrow mx-auto mobile-banner"
      v-show="userDetails.showVerificationMessage"
      role="alert"
    >
      Your account has been verified
    </div>
      <h1 class="text-uppercase welcome-desc">{{pageContent && pageContent.didYouKnowHeader ? pageContent.didYouKnowHeader : 'DID YOU KNOW?' }}</h1>
      <!-- <ProgressBar :currentValue="progress"></ProgressBar> -->
      <h4 class="welcome-desc font-weight-normal" v-html="pageContent && pageContent.didYouKnowSubHeader ? pageContent.didYouKnowSubHeader : defaultSubHeader">
      </h4>
      <div class="row" v-if="displayWidth > 500">
        <div class="col-md-6 col-6">
          <div class="card new-card"><div class="card-body d-flex flex-column justify-content-between">
              <h5 class="card-title new-title">{{pageContent && pageContent.industryHeader ? pageContent.industryHeader : 'INDUSTRY COLLABORATION' }}</h5>
              <p class="card-text text-light">
                Write your thesis on a topic <span class="font-weight-bold">independent</span> of the <span class="font-weight-bold">thesis company</span>. <br /> <br /> An <span class="font-weight-bold">industry expert</span> from the company provides you with professional <span class="font-weight-bold">guidance and sparring.</span>
                <!-- {{pageContent && pageContent.industryContent ? pageContent.industryContent : htmlToText(defaultIndustryDesc) }} -->
                </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-6">
          <div class="card new-card">
            <div class="card-body d-flex flex-column justify-content-between">
              <h5 class="card-title new-title">{{pageContent && pageContent.caseHeader ? pageContent.caseHeader : 'CASE COLLABORATION' }}</h5>
              <p class="card-text text-light" v-if="pageContent && pageContent.caseContent" v-html="pageContent.caseContent"></p>
              <p class="card-text text-light" v-else>
                Write your thesis on a topic <span class= "font-weight-bold" > dependant</span> of the <span class="font-weight-bold" > thesis company</span >. <br /> <br /> A company supervisor works in <span class="font-weight-bold">close collaboration</span> with you based on <span class="font-weight-bold" > your project proposal.</span >
                <!-- {{ ? pageContent.caseContent : htmlToText(defaultCaseDesc)}} -->
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="accordion" v-else>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <a
                class="btn btn-link collapsed d-flex align-items-center"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                {{ pageContent && pageContent.industryHeader ? pageContent.industryHeader : 'INDUSTRY COLLABORATION' }}
                <i class="fa ml-2"></i>
              </a>
            </h5>
          </div>
          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p class="card-text text-light" v-html="pageContent && pageContent.industryContent ? pageContent.industryContent : defaultIndustryDesc">
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <a
                class="btn btn-link collapsed d-flex align-items-center"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                {{ pageContent && pageContent.caseHeader ? pageContent.caseHeader : 'CASE COLLAORATION' }}
                <i class="fa ml-2"></i>
              </a>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p class="card-text text-light" v-if="pageContent && pageContent.caseContent" v-html="pageContent.caseContent"></p>
              <p class="card-text text-light" v-else>Write your thesis on a topic <span class= "font-weight-bold" > dependant</span> of the <span class="font-weight-bold" > thesis company</span >. <br /> <br /> A company supervisor works in <span class="font-weight-bold">close collaboration</span> with you based on <span class="font-weight-bold" > your project proposal.</span ></p>
            </div>
          </div>
        </div>
      </div>
      <h5 class="font-weight-bold">{{pageContent && pageContent.collaborationBenefitsHeader ? pageContent.collaborationBenefitsHeader : 'Collaboration benefits' }}</h5>
      <template v-if="benefitsList && benefitsList.length > 0">
      <p v-for="benefit in benefitsList" :key="benefit.benefit_id">
        {{ getBenefit(benefit) }}
      </p>
      </template>
      <template v-else>
              <p>
          On-site office desk
          <!-- <img src="~@/assets/info.png" alt="info" class="info-image" /> -->
        </p>
        <p>
          Access to data
          <!-- <img src="~@/assets/info.png" alt="info" class="info-image" /> -->
        </p>
        <p>
          Professional guidance
          <!-- <img src="~@/assets/info.png" alt="info" class="info-image" /> -->
        </p>
        <p>
          Personal company supervisor
          <!-- <img src="~@/assets/info.png" alt="info" class="info-image" /> -->
        </p>
      </template>
      <div
        class="d-flex justify-content-end mt-5"
        :class="{ bottom_button_group: !isContentLong }"
      >
        <button class="btn btn-white ml-2 Explorer-DidYouKnow-Next w-200">
          Complete signup
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'DidYouKnow',
  props: ['handleSubmit', 'progress', 'handleBack', 'userDetails'],
  data() {
    return {
      isContentLong: false,
      displayWidth: 0,
      defaultSubHeader: 'On Excelerate you can find <span>two types</span> of thesis collaborations.',
      defaultIndustryDesc: 'Write your thesis on a topic <span class="font-weight-bold">independent</span> of the <span class="font-weight-bold">thesis company</span>. <br /> <br /> An <span class="font-weight-bold">industry expert</span> from the company provides you with professional <span class="font-weight-bold">guidance and sparring.</span> ',
      defaultCaseDesc: ''
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    const contentHeight = this.$refs.content && this.$refs.content.offsetHeight + 80; // get height of your content element
    const viewportHeight = window.innerHeight; // get height of device viewport
    // console.log(viewportHeight, contentHeight+80)
    if (contentHeight > viewportHeight) {
      this.isContentLong = true;
      return;
    }
    this.isContentLong = false;
    setTimeout(() => {
      this.$store.dispatch('updateUserDetails', {
        ...this.userDetails,
        showVerificationMessage: false,
      });
    }, 4000);
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    isLoading(){
      return this.$store.getters.dynamicIsLoading
    },
    pageContent(){
      return this.$store.getters.explorerDidYouKnowContent
    },
    benefitsList(){
      return this.$store.getters.explorerDidYouKnowBenefitsContent
    },
  },
  methods: {
    handleResize() {
      this.displayWidth = window.innerWidth;
    },
    getBenefit(benefit){
      const benefitObj = JSON.parse(benefit.benefit_data)
      return benefitObj[1];
    }
  },
};
</script>

<style scoped>
.card .card-body {
  padding: 0.2rem !important;
}
.card .card-text {
  padding: 10%;
  background-color: #5a2871 !important;
  font-family: 'Poppins', sans-serif;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.new-card {
  box-shadow: none;
  border: 3px;
  border-radius: 10px;
}
.btn-white {
  background: #fff;
  border: 1px solid #fff;
  width: 200px;
  color: #5a2871;
}
#app #app-master #page .user-flow .user-form form {
  padding: 60px 10px 30px !important;
}
.new-title {
  color: #5a2871;
  font-weight: 700;
  margin-bottom: 0;
  margin: 22px 0px;
}

p {
  margin: 0;
}
.info-image {
  margin-bottom: 5px;
}
.user-flow .user-splash {
  border-radius: 10px !important;
}

@media (max-width: 767px) {
  .user-splash {
    display: block !important;
    margin-top: -30px;
    margin-bottom: -55px;
  }

  .card .card-text {
    font-size: 13px;
    line-height: 20px;
  }
  .new-title {
    font-size: 16px;
  }
  .user-flow .user-splash {
    border-radius: 0 !important;
  }
}
@media (max-width: 500px) {
  .mobile_body {
    min-height: 100vh !important;
  }
  .card-header a {
    color: #5a2871 !important;
    font-size: 18px;
    font-weight: bold;
    text-align: center !important;
  }
  .card-header i {
    color: #5a2871 !important;
  }
  [data-toggle='collapse'] .fa:after {
    content: '\f068';
  }
  [data-toggle='collapse'].collapsed .fa:after {
    content: '\f067';
  }
  .card{
    border-radius: 10px !important;
  }
}
@media (min-width: 499px) {
  .mobile-banner {
    display: none
  }
}
</style>
