var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 user-form"},[(_vm.loadingAllInterests || _vm.loadingUserInterests)?_c('div',{staticClass:"loader"},[_vm._v("Loading...")]):_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(_vm.isZealandFlow)?_c('h1',{staticClass:"text-uppercase common-text"},[_vm._v("Dine interesseområder ")]):_c('h1',{staticClass:"text-uppercase common-text"},[_vm._v(" "+_vm._s(_vm.pageContent && _vm.pageContent.formHeader ? _vm.pageContent.formHeader : 'YOUR RESEARCH INTERESTS'))]),_c('ProgressBar',{attrs:{"currentValue":_vm.progress}}),(_vm.isZealandFlow)?_c('h4',{staticClass:"welcome-desc font-weight-normal"},[_vm._v("Hvad er dine interesseområder? Klik for at vælge. ")]):_c('h4',{staticClass:"welcome-desc font-weight-normal"},[_vm._v(" "+_vm._s(_vm.pageContent && _vm.pageContent.formSubHeader ? _vm.pageContent.formSubHeader : 'Which research area(s) interest you? Click to select.')+" ")]),_c('div',{staticClass:"main-card-box"},_vm._l((_vm.categoryList),function(item){return _c('div',{key:item.id,staticClass:"card card-custom",class:{
        active_card:
          _vm.researchInterests && _vm.researchInterests.includes(item.id),
      },on:{"click":function($event){return _vm.toggleSelect(item.id)}}},[_c('div',{staticClass:"card-body d-flex custom-card-body"},[(_vm.researchInterests && _vm.researchInterests.includes(item.id))?_c('div',{},[_c('img',{staticClass:"image-list",attrs:{"src":item.lite_image,"alt":item.category_name}})]):_c('div',{},[_c('img',{staticClass:"image-list",attrs:{"src":item.image,"alt":item.category_name}})]),_c('h6',{staticClass:"card-subtitle text-muted pl-2",class:{
            active_card_text:
              _vm.researchInterests && _vm.researchInterests.includes(item.id),
          }},[_vm._v(" "+_vm._s(item.category_name)+" ")])])])}),0),(_vm.error && _vm.researchInterests.length === 0)?_c('div',{staticClass:"alert alert-danger mt-3"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between mt-5 button_group"},[_c('button',{staticClass:"btn btn-link Explorer-YourResearchInterests-Back",on:{"click":_vm.handleBack}},[_c('i',{staticClass:"fas fa-chevron-left"}),(_vm.isZealandFlow)?[_vm._v(" Tilbage ")]:[_vm._v(" Back ")]],2),_c('button',{staticClass:"btn btn-primary Explorer-YourResearchInterests-Next w-200",attrs:{"disabled":_vm.isUpdating}},[(_vm.isUpdating)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_c('span',[(_vm.isZealandFlow)?[_vm._v(" Næste ")]:[_vm._v(" "+_vm._s(_vm.pageContent && _vm.pageContent.formNextButton ? _vm.pageContent.formNextButton : 'Next')+" ")]],2)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }