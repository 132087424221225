<template>
  <div class="col-12 user-form">
    <form action="">
      <h1 v-if="isZealandFlow" class="text-uppercase common-text">HIGH FIVE!</h1>
      <h1 v-else class="text-uppercase common-text">
        {{ pageContent && pageContent.formTitle ? pageContent.formTitle : 'CONGRATULATIONS' }}</h1>
      <p v-if="isZealandFlow" style="font-size: 14px" class="font-weight-normal">
        Du er nu
      </p>
      <p v-else style="font-size: 14px" class="font-weight-normal">
        {{ pageContent && pageContent.formSubTitle ? pageContent.formSubTitle : 'Your profile level is now explorer' }}
      </p>
      <h1 class="header-text" style="">
        {{ pageContent && pageContent.explorerTitle ? pageContent.explorerTitle : 'EXPLORER' }}</h1>
      <img v-if="pageContent && pageContent.formImage" :src="pageContent.formImage" alt="search icon"
        class="m-26 dynamic-image" srcset="" />
      <img v-else src="~@/assets/search-big-purple.png" alt="search icon" class="m-26" srcset="" />
      <p class="body-text" v-if="isZealandFlow">Sådan! Du er nu klar til at udforske Zealand virksomheder på Excelerate.
      </p>
      <p v-else class="body-text">
        {{ pageContent && pageContent.formText1 ? pageContent.formText1 : 'You can now search inspiration, see student thesis topics, and get professional guidance on your thesis project.' }}
      </p>
      <strong class="body-text" v-if="isZealandFlow">Klar til at matche med virksomheder?</strong>
      <strong v-else class="body-text">
        {{ pageContent && pageContent.formText2 ? pageContent.formText2 : 'Ready to be matched with companies?' }}</strong>
      <p class="body-text bottom-spacing" v-if="isZealandFlow">For at forøge matchingmuligheder med virksomheder, skal
        du først færdiggøre din Excelerate profil.</p>
      <p v-else class="body-text bottom-spacing">
        {{ pageContent && pageContent.formText3 ? pageContent.formText3 : 'Upgrade your profile with more information to reach next level before we can match you with companies.' }}
      </p>
      <rising-star-progress-bar :currentValue="progress"></rising-star-progress-bar>
      <div v-if="isZealandFlow" :class="{ bottom_button_group: isContentLong }">
        <router-link to="/events/Zealand-Job--og-Karrieredag-2024"
          class="btn btn-block btn-primary Explorer-Congratulations-Next">Udforsk
          og match med
          virksomheder</router-link>
        <router-link class="btn btn-block button-outline Explorer-Congratulations-Dashboard" to="/">Færdiggør din
          Excelerate profi</router-link>
      </div>
      <div v-else :class="{ bottom_button_group: isContentLong }">
        <router-link to="/user/student-rising-star"
          class="btn btn-block btn-primary Explorer-Congratulations-Next">{{ pageContent && pageContent.formRisingButton ? pageContent.formRisingButton : 'Upgrade profile to be matched' }}</router-link>
        <router-link class="btn btn-block button-outline Explorer-Congratulations-Dashboard"
          to="/">{{ pageContent && pageContent.formExplorerButton ? pageContent.formExplorerButton : 'Go to Explorer' }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import RisingStarProgressBar from '../RisingStarProgressBar.vue';

export default {
  name: 'StudentExplorer',
  components: { RisingStarProgressBar },
  props: ['progress'],
  data() {
    return {
      isContentLong: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    const contentHeight = this.$refs.content && this.$refs.content.offsetHeight + 80; // get height of your content element
    const viewportHeight = window.innerHeight; // get height of device viewport
    // console.log(viewportHeight, contentHeight+80)
    if (contentHeight > viewportHeight) {
      this.isContentLong = true;
      return;
    }
    this.isContentLong = false;
  },
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    pageContent() {
      return this.$store.getters.explorerCongratsContent
    }
  }

};
</script>

<style scoped>
.button-outline {
  border: 2px solid #5a2871;
}

.header-text {
  font-size: 54px !important;
  color: #5a2871;
  font-weight: 700;
}

.m-26 {
  margin-top: 26px;
  margin-bottom: 30px;
}

.body-text {
  font-size: 18px;
}

.bottom-spacing {
  margin-bottom: 40px;
}

.dynamic-image {
  max-width: 300px
}

@media (max-width: 499px) {
  .common-text {
    margin-bottom: 10px;
  }

  .bottom-spacing {
    margin-bottom: 60px;
  }
}
</style>

<style>@media (max-width: 499px) {
  .lets_test_this {
    margin-left: 18px;
  }
}</style>
