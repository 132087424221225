<template>
  <div class="col-12 user-form">
    <form @submit.prevent="validateForm">
      <h1 v-if="isZealandFlow" class="text-uppercase common-text">VERIFICER PROFIL</h1>
      <h1 v-else class="text-uppercase common-text">FINAL STEP</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 v-if="isZealandFlow" class="mb-4 font-weight-normal">
        Vi har sendt en 6-cifret verificeringskode til din email.
      </h4>
      <h4 v-else class="mb-4 font-weight-normal">
        We have sent a 6-digit verification code to your email.
      </h4>
      <h4 v-if="isZealandFlow" class="welcome-desc font-weight-normal">
        Indtast koden nedenfor for at verificere din profil.
      </h4>
      <h4 v-else class="welcome-desc font-weight-normal">
        Enter the code below to verify your profile.
      </h4>
      <div class="form-group text-left" v-if="isZealandFlow">
        <label>Verificér din profil</label>
        <input type="text" required v-model="verificationCode" name="code"
          class="form-control form-control-lg input-color" placeholder="Verificeringskode" />
        <div class="alert alert-danger mt-3" v-if="verifyMessageError">
          {{ verifyMessageError }}
        </div>
        <div class="alert alert-success mt-3" v-if="verifyMessageSuccess">
          {{ verifyMessageSuccess }}
        </div>
      </div>
      <div class="form-group text-left" v-else>
        <label>Verify profile</label>
        <input type="text" required v-model="verificationCode" name="code"
          class="form-control form-control-lg input-color" placeholder="Verification code" />
        <div class="alert alert-danger mt-3" v-if="verifyMessageError">
          {{ verifyMessageError }}
        </div>
        <div class="alert alert-success mt-3" v-if="verifyMessageSuccess">
          {{ verifyMessageSuccess }}
        </div>
      </div>
      <div class="text-right">
        <button v-if="isZealandFlow" type="button" class="btn Explorer-FinalStep-Resend text-right color-third"
          @click="resendVerificationEmail">
          Gensend kode
        </button>
        <button v-else type="button" class="btn Explorer-FinalStep-Resend text-right color-third"
          @click="resendVerificationEmail">
          Resend code
        </button>
      </div>
      <div class="form-group">
        <div class="custom-control custom-checkbox mb-1 text-left">
          <input type="checkbox" class="custom-control-input" name="role" id="signupTerms" v-model="terms" value="terms"
            required />
          <label v-if="isZealandFlow" class="custom-control-label" for="signupTerms">Jeg accepterer
            <router-link to="/user/terms" target="_blank" class="color-third Explorer-FinalStep-Terms">tvilkår og
              betingelser.</router-link></label>
          <label v-else class="custom-control-label" for="signupTerms">I accept
            <router-link to="/user/terms" target="_blank" class="color-third Explorer-FinalStep-Terms">the terms and
              conditions</router-link></label>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-5 bottom_button_group" v-if="isZealandFlow">
        <button class="btn btn-link Explorer-FinalStep-Back" @click="handleBack">
          <i class="fas fa-chevron-left"></i> Tilbage
        </button>
        <button class="btn btn-primary ml-2 Explorer-FinalStep-Next w-200" :disabled="isLoading">
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>
            Verificér din profil
          </span>
        </button>
      </div>
      <div class="d-flex justify-content-between mt-5 bottom_button_group" v-else>
        <button class="btn btn-link Explorer-FinalStep-Back" @click="handleBack">
          <i class="fas fa-chevron-left"></i> Back
        </button>
        <button class="btn btn-primary ml-2 Explorer-FinalStep-Next w-200" :disabled="isLoading">
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>
            Verify my profile
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { base64ToString } from '../../utils/utils';
import ProgressBar from '../ProgressBar.vue';

let userId = localStorage.getItem('uid')
userId = base64ToString(userId)

export default {
  name: 'StudentVerification',
  components: {
    ProgressBar,
  },
  data() {
    return {
      verificationCode: null,
      terms: false,
    }
  },
  props: [
    'progress',
    'handleBack',
    'verifyMessageError',
    'verifyMessageSuccess',
  ],
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    isLoading() {
      return this.$store.getters.userIsLoading;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    email() {
      return this.$store.getters.studentData.email
    },
  },
  methods: {
    validateForm() {
      this.$emit('handle-submit', 'verification', this.verificationCode)
    },
    resendVerificationEmail() {
      var vm = this;
      const verificationEmailData = {
        email: this.email,
      };
      vm.$store
        .dispatch('resendVerificationCode', verificationEmailData)
        .then(() => {
          vm.verifyMessageSuccess = 'Check your inbox';
        }).catch((err) => console.log(err))
    },
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0;
}

@media (max-width: 499px) {
  .common-text {
    font-size: 24px;
  }
}
</style>
